$env: production;
@import '@/styles/_component-base.styles.scss';

.baseLink {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 75%;
    max-height: 40px;
    padding: spacer(sm) spacer(xs);
    font-weight: normal;

    &:hover {
        opacity: 0.85;
    }

    @include breakpoint(md) {
        padding: spacer(sm) spacer(base);
        font-size: set-fontSize(18);
    }

    @include breakpoint(lg) {
        padding: spacer(sm) spacer(base);
        font-size: set-fontSize(20);
    }
}

.homeHero {
    height: 500px;
    position: relative;

    @include breakpoint(2xl) {
        height: 600px;
    }

    @media only screen and (min-width: 2560px) {
        height: 700px;
    }

    .heading {
        width: 100%;
        padding-top: 0;
        text-align: center;
        font-size: set-fontSize(32);
        color: color(contrast, white);

        @include breakpoint(md) {
            font-size: set-fontSize(40);
        }

        @include breakpoint(2xl) {
            font-size: set-fontSize(44);
        }
    }

    .description {
        display: block;
        text-align: center;
        color: color(contrast, white);
        font-size: set-fontSize(16);
        padding: spacer(xs) spacer(xs) 0 spacer(xs);

        @include breakpoint(md) {
            padding-top: spacer(base);
        }

        @include breakpoint(lg) {
            font-size: set-fontSize(20);
            padding-top: spacer(xs);
        }

        @include breakpoint(2xl) {
            font-size: set-fontSize(24);
        }
    }

    .heroContainer {
        position: relative;
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: spacer(3xl);

        @include breakpoint(md) {
            padding-top: spacer(5xl);
        }

        @include breakpoint(2xl) {
            padding-top: spacer(7xl);
        }

    }

    .ctaList {
        display: grid;
        gap: 15px;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        padding-top: spacer(base);

        @include breakpoint(md) {
            display: flex;
            gap: 0px;
            align-items: center;
            justify-content: center;
            padding-top: spacer(md) + spacer(3xs);
        }

        @include breakpoint(lg) {
            padding-top: spacer(xs);
        }
    }

    .button {
        @extend .baseLink;
        color: color(contrast, white) !important;
        background-color: color(accent, blue);
        font-size: set-fontSize(16);
        line-height: normal;
        height: 40px;
        width: 199px;

        @include breakpoint(md) {
            margin-right: spacer(3xl);
        }
        @include breakpoint(2xl) {
            font-size: set-fontSize(20);
            width: 250px;
        }
    }

    .link {
        @extend .baseLink;
        text-decoration: underline;
        font-family: $fontFamily-serif;

        &--white {
            color: color(contrast, white) !important;
        }

        &--black {
            color: color(text, base) !important;
        }
    }

    .bottomLink {
        text-decoration: underline;
        position: absolute;
        bottom: 0;
        left: 0;
        font-weight: normal;
        padding: spacer(sm);
    }

    .linkIcon {
        stroke-width: 4px;
        margin-left: spacer(2xs);
        height: 10px;
        width: 10px;

        @include breakpoint(md) {
            height: 14px;
        }

        &--white {
            stroke: color(contrast, white);
        }

        &--black {
            stroke: color(text, base);
        }
    }
}
